<ng-container *ngIf="name">
  <ngx-spinner
    [showSpinner]="true"
    bdOpacity="0.5"
    bdColor="rgba(51,51,51,0.2)"
    size="medium"
    color="#fff"
    type="timer"
    [fullScreen]="fullScreen"
    [name]="name"
  >
  </ngx-spinner>
</ng-container>
<ng-container *ngIf="!name">
  <ngx-spinner
    [showSpinner]="true"
    bdOpacity="0.5"
    bdColor="rgba(51,51,51,0.2)"
    size="medium"
    color="#fff"
    type="timer"
    [fullScreen]="fullScreen"
  ></ngx-spinner>
</ng-container>
