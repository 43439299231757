import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'fgp-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SpinnerComponent {
  @Input() fullScreen = false;
  @Input() name: string;
}
